.pharmacyCard{
    width: 100%;
    height: 106px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 4px 4px 0px #0000000D;
    border-radius: 8px;
    background-color: rgba(10, 145, 228, 0.1);

}

.pharmacyCardContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: #0A91E4;
}

.pharmacyCardDistance{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    max-width: 30%;
}

.pharmacyCardInfo{
    width: 80%;
    height: 90px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: auto;
    gap: 8px;
    h3{
        font-weight: bold;
        font-size: 13px;
        justify-self: flex-start;
    }
    p{
        font-size: 12px;
        width: 80%;
        overflow: auto;
    }
}